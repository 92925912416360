import React from 'react';
import { useEffect } from "react";
import styled from 'styled-components';
import Footer from './footer';

// Styled components for FAQ container, heading, item, question, and answer
const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
`;

const FAQContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const FAQHeading = styled.h2`
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
`;

const FAQItem = styled.div`
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
  &:last-child {
    border-bottom: none;
  }
`;

const FAQQuestion = styled.div`
  font-weight: bold;
  font-size: 18px;
  color: #555;
  margin-bottom: 10px;
`;

const FAQAnswer = styled.div`
  font-size: 16px;
  color: #666;
`;

const FAQ = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Scroll to top when component mounts

  const faqs = [
    {
      question: 'How do I make a claim?',
      answer: 'To file a claim, please fill out the claim form with detailed information. Provide your email and phone number for further communication.'
    },
    {
      question: 'How will I know if my claim is being processed?',
      answer: 'After filing a claim, you will receive confirmation via email. You will also receive status update emails as your claim progresses.'
    },
    {
      question: 'Is my product covered by warranty?',
      answer: 'Check the Terms and Conditions to verify if your item is covered by the warranty.'
    },
    {
      question: 'Can I add warranty coverage after the purchase?',
      answer: 'Yes, you can purchase extended warranty within 30 days of your original purchase date.'
    },
    {
      question: 'How do I find my customer number?',
      answer: 'Your customer number is listed on your receipt. If you don\'t have the receipt, provide details in the form to locate your warranty in the system.'
    }
  ];

  return (
    <>
      <PageContainer>
        <FAQContainer>
          <FAQHeading>Frequently Asked Questions</FAQHeading>
          {faqs.map((faq, index) => (
            <FAQItem key={index}>
              <FAQQuestion>{faq.question}</FAQQuestion>
              <FAQAnswer>{faq.answer}</FAQAnswer>
            </FAQItem>
          ))}
        </FAQContainer>
      </PageContainer>
      <Footer />
    </>
  );
};

export default FAQ;
