import React from "react";
import appliancesImage from "../images/Appliances.jpg"; // Assuming you have an image file in your project
import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

const Appliances = () => {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Scroll to top when component mounts


  const handleFileClaim = () => {
    history.push("/claim"); // Navigate to /claim route
    window.scrollTo(0, 0); // Scroll to top after navigation
  };


  return (
    <div className="container-fluid">
      {/* Title container without background color */}
      <div className="p-4 mb-3 text-center">
        <h1 className="font-weight-bold mb-3">Appliances</h1>
        <p className="mb-0">Extended Warranty Plan</p>
      </div>

      {/* Image and text container using Bootstrap grid */}
      <div className="row">
        {/* Image column */}
        <div className="col-md-6">
          <div className="text-center mb-5" >
            <img
              src={appliancesImage}
              alt="appliancesImage"
              className="img-fluid rounded shadow"
              style={{ maxWidth: "100%", maxHeight: "600px", objectFit: "cover", marginTop: "33px" }}
            />
          </div>
        </div>

        {/* Text column */}
        <div className="col-md-6">
          <div className="p-4">
            <h2 className="font-weight-bold mb-4">
              Alliance Warranty Solutions Extended Warranty for Major and Small
              Appliances
            </h2>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              {/* No one enjoys coming home feeling of coming home after a long day of hard work,
              hungry, and discovering that the oven doesn’t work to prepare your
              dinner. The overwhelming anxiety of your food going bad after your
              fridge stops working, never feels good. And while major appliances are
              essential to your household, it's the small appliances that bring
              everything together. When you're in a hurry, but need to eat something
              fast, your microwave allows you to enjoy a nice hot meal. Need coffee
              in the morning? Your coffee maker makes it easy and convenient enough
              for you to receive your jump-start in the morning. These big and small
              appliances are so vital to your lifestyle that it'd be wise to make
              sure they're covered, in case they were to break down. Finding someone
              to come in to fix the issues can be frustrating, will cost money, and
              could result in needing the whole thing replaced, anyway. When we are
              flustered, nobody has time for trying to learn the ins-and-outs of a
              major appliance, on the fly. With Alliance Warranty Solutions, service
              is only a phone call away, and your repair won’t cost you anything.
              This warranty covers the service call, labor charges, and even
              replacement parts. The best part is, you’d be covered no matter where
              you live. With nationwide service, easy replacement and repairs, and
              no hidden costs or deductibles, Alliance Warranty Solutions is the
              perfect choice for a minimum investment and maximum protection. */}
              No one enjoys coming home after a long, hard day, only to find the oven broken and unable to prepare dinner. The anxiety of food spoiling when the refrigerator stops working is equally distressing. While major appliances are essential to your household, small appliances complete the picture. In a hurry and need a quick meal? Your microwave ensures you have a hot meal in minutes. Need coffee in the morning? Your coffee maker provides a convenient jump-start to your day. Given the importance of these appliances, it is wise to ensure they are covered in case of a breakdown. Finding a repair technician can be frustrating, expensive, and may even result in needing a replacement. When you're already flustered, learning the intricacies of a major appliance is the last thing you need. With Alliance Warranty Solutions, help is just a click away, and repairs won’t cost you a thing. Our warranty covers service calls, labor charges, and replacement parts. Best of all, you are covered no matter where you live. Alliance Warranty Solutions offers the perfect combination of minimal investment and maximum protection.</p>
            {/* Button positioned at bottom-right corner */}
            <div className="d-flex justify-content-end">
              {/* Use Link to navigate to Form.js */}
              <Link to="/searchwarranty">
                <button
                  type="button"
                  className="btn btn-primary mt-3"
                  onClick={handleFileClaim}
                >
                  File Claim
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Add spacing between image and text */}
      <div style={{ marginBottom: "50px" }}></div>
    </div>
  );
};

export default Appliances;
