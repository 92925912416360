import React from "react";
import { Link } from "react-router-dom";
import smartphoneImage from "../images/Smartphones.jpg";
import computerImage from "../images/Laptops.jpg";
import tvImage from "../images/TVs.jpg";
import cameraImage from "../images/Cameras.jpg";
import appliancesImage from "../images/Appliances.jpg";

const Fronta = () => {
  const hoverStyle = {
    transition: "transform 0.3s ease",
  };

  return (
    <div className="container-fluid">
      <div className="container text-center mt-5 px-3">
        <h2
          className="mb-4"
          style={{
            fontFamily: "Arial, sans-serif",
            fontSize: "1.5rem",
            color: "#333",
            textDecoration: "none",
          }}
        >
          "How may we assist you today?"

        </h2>
      </div>

      <div className="row justify-content-center">
        <div className="col-sm-2">
          <Link to="/smartphones" className="card-link" style={{ textDecoration: "none" }}>
            <div className="card border-0 shadow-sm mb-4"
              style={{ height: "100%", ...hoverStyle }}
              onMouseOver={(e) => e.currentTarget.style.transform = "scale(1.1)"}
              onMouseOut={(e) => e.currentTarget.style.transform = "scale(1)"}
            >
              <img
                src={smartphoneImage}
                className="card-img-top"
                alt="Phones"
              />
              <div className="card-body text-center">
                <h5 className="card-title mb-0" style={{ color: "black" }}>Phone</h5>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-sm-2">
          <Link to="/computers" className="card-link" style={{ textDecoration: "none" }}>
            <div className="card border-0 shadow-sm mb-4"
              style={{ height: "100%", ...hoverStyle }}
              onMouseOver={(e) => e.currentTarget.style.transform = "scale(1.1)"}
              onMouseOut={(e) => e.currentTarget.style.transform = "scale(1)"}
            >
              <img
                src={computerImage}
                className="card-img-top"
                alt="Computers"
              />
              <div className="card-body text-center">
                <h5 className="card-title mb-0" style={{ color: "black" }}>Computer</h5>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-sm-2">
          <Link to="/tv" className="card-link" style={{ textDecoration: "none" }}>
            <div className="card border-0 shadow-sm mb-4"
              style={{ height: "100%", ...hoverStyle }}
              onMouseOver={(e) => e.currentTarget.style.transform = "scale(1.1)"}
              onMouseOut={(e) => e.currentTarget.style.transform = "scale(1)"}
            >
              <img
                src={tvImage}
                className="card-img-top"
                alt="TV's & Monitors"
              />
              <div className="card-body text-center">
                <h5 className="card-title mb-0" style={{ color: "black" }}>TV's & Monitor</h5>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-sm-2">
          <Link to="/camera" className="card-link" style={{ textDecoration: "none" }}>
            <div className="card border-0 shadow-sm mb-4"
              style={{ height: "100%", ...hoverStyle }}
              onMouseOver={(e) => e.currentTarget.style.transform = "scale(1.1)"}
              onMouseOut={(e) => e.currentTarget.style.transform = "scale(1)"}
            >
              <img
                src={cameraImage}
                className="card-img-top"
                alt="Camera & Gaming"
              />
              <div className="card-body text-center">
                <h5 className="card-title mb-0" style={{ color: "black" }}>Camera</h5>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-sm-2">
          <Link to="/appliances" className="card-link" style={{ textDecoration: "none" }}>
            <div className="card border-0 shadow-sm mb-4"
              style={{ height: "100%", ...hoverStyle }}
              onMouseOver={(e) => e.currentTarget.style.transform = "scale(1.1)"}
              onMouseOut={(e) => e.currentTarget.style.transform = "scale(1)"}
            >
              <img
                src={appliancesImage}
                className="card-img-top"
                alt="Appliances"
              />
              <div className="card-body text-center">
                <h5 className="card-title mb-0" style={{ color: "black" }}>Appliance</h5>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Fronta;
