import React from "react";
import cameraImage from "../images/Cameras.jpg";
import { Link, useHistory } from "react-router-dom";
import { useEffect } from "react";

const Camera = () => {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Scroll to top when component mounts

  const handleFileClaim = () => {
    history.push("/claim"); // Navigate to /claim route
    window.scrollTo(0, 0); // Scroll to top after navigation
  };

  return (
    <div className="container-fluid">
      {/* Title container without background color */}
      <div className="p-4 mb-3 text-center">
        <h1 className="font-weight-bold mb-3">Camera</h1>
        <p className="mb-0">Extended Warranty Plan</p>
      </div>

      {/* Image and text container using Bootstrap grid */}
      <div className="row">
        {/* Image column */}
        <div className="col-md-6">
          <div className="text-center mb-4">
            <img
              src={cameraImage}
              alt="cameraImage"
              className="img-fluid"
              style={{ maxWidth: "100%", maxHeight: "600px", objectFit: "cover",marginTop: "33px"}}
            />
          </div>
        </div>

        {/* Text column */}
        <div className="col-md-6">
          <div className="bg-white p-4 mb-3">
            <h2 className="font-weight-bold mb-4">
              Alliance Warranty Solutions Extended Warranty for Cameras
            </h2>
            <p className="mb-0" style={{ textAlign: "justify" }}>
              Whether it’s for your career in photography, taking gorgeous
              images as a hobby, or for simply capturing the heartfelt moments
              in life, cameras are a true investment in creating the best
              souvenirs. The greater the moment, the greater you want the
              picture quality to be. Needless to say, good cameras aren’t cheap,
              but the memories they store are priceless. When you sit down with
              friends and family to look back on photos, you know it was all
              worth it. That being said, it would be a shame if you dropped your
              camera, broke it, and now have to save up money to get one just as
              good. Day by day, missed opportunities go by, while you wish you
              could have captured the things you’ve seen and experienced. Though
              having something go wrong is not part of the game plan, we also
              understand that these cameras are not immune to unexpected falls
              or malfunctions. With Alliance Warranty Solutions, service is only
              a phone call away, and your repair won’t cost you anything. This
              warranty covers the service call, labor charges, and even
              replacement parts. The best part is, you’d be covered no matter
              where you live. With nationwide service, easy replacement and
              repairs, and no hidden costs or deductibles, Alliance Warranty
              Solutions is the perfect choice for a minimum investment and
              maximum protection.
            </p>
            {/* Button positioned at bottom-right corner */}
            <div className="d-flex justify-content-end">
              {/* Use Link to navigate to Form.js */}
              <Link to="/searchwarranty">
                <button
                  type="button"
                  className="btn btn-primary mt-3"
                  onClick={handleFileClaim}
                >
                  File Claim
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Camera;
