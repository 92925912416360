import React from "react";
import smartphoneImage from "../images/Smartphones.jpg"; // Assuming you have an image file in your project
import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

const Smartphones = () => {

  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Scroll to top when component mounts

  const handleFileClaim = () => {
    history.push("/claim"); // Navigate to /claim route
    window.scrollTo(0, 0); // Scroll to top after navigation
  };
  return (
    <div className="container-fluid">
      {/* Title container without background color */}
      <div className="p-4 mb-3 text-center">
        <h1 className="font-weight-bold mb-3">Smartphones</h1>
        <p className="mb-0">Extended Warranty Plan</p>
      </div>

      {/* Image and text container using Bootstrap grid */}
      <div className="row">
        {/* Image column */}
        <div className="col-md-6">
          <div className="text-center mb-5" >
            <img
              src={smartphoneImage}
              alt="smartphoneImage"
              className="img-fluid rounded shadow"
              style={{ maxWidth: "100%", maxHeight: "600px", objectFit: "cover", marginTop: "33px" }}
            />
          </div>
        </div>

        {/* Text column */}
        <div className="col-md-6">
          <div className="p-4">
            <h2 className="font-weight-bold mb-4">
              Alliance Warranty Solutions Extended Warranty for Smartphone
            </h2>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              Our smartphones have become indispensable tools, seamlessly integrated into our daily lives. Whether we are making calls, video-chatting, playing games, emailing, or making payments, these devices serve a multitude of functions. If a smartphone were to malfunction, it could severely disrupt many essential tasks. Consider the scenario of being on the go for work, needing to check emails, but your phone suddenly stops working and you cannot afford a new one immediately. Smartphone repairs and replacements are notoriously expensive. Given its importance, doesn't it make sense to protect it?
              <br />
              With Alliance Warranty Solutions, service is just a click away, and repairs won’t cost you anything. This warranty covers service calls, labor charges, and even replacement parts. The best part is, you are covered no matter where you live. Alliance Warranty Solutions offers an ideal combination of minimal investment and maximum protection.
            </p>
            {/* Button positioned at bottom-right corner */}
            <div className="d-flex justify-content-end">
              {/* Use Link to navigate to Form.js */}
              <Link to="/searchwarranty">
                <button
                  type="button"
                  className="btn btn-primary mt-3"
                  onClick={handleFileClaim}
                >
                  File Claim
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Smartphones;
