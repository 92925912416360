

import React from "react";

const statesArray = [
  "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", 
  "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", 
  "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", 
  "WI", "WY"
];

const StatesDropdown = ({ selectedState, setSelectedState, readOnly }) => {
  return (
    <select
      className="form-select"
      value={selectedState}
      onChange={(e) => setSelectedState(e.target.value)}
      disabled={readOnly}
      required
    >
      <option value="">Choose...</option>
      {statesArray.map((state) => (
        <option key={state} value={state}>
          {state}
        </option>
      ))}
    </select>
  );
};

export default StatesDropdown;
