import React from "react";
import promoVideo from "../promo_video.mp4";
import logoImage from "../images/logo/aws_full_logo.png";

const Front = () => {
  return (
    <div>

        {/* changing the properties of the video dimensions from both the sides and all */}
      <div style={{ position: "relative", width: "100vw", maxHeight: "80vh", overflow: "hidden" }}>
        <video autoPlay loop muted style={{ width: "100%", height: "100%" }}>
          <source src={promoVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center", color: "#ffffff" }}>
          <img src={logoImage} alt="Background" style={{ maxWidth: "100%", maxHeight: "100%" }} />
        </div>
      </div>
      <div style={{ textAlign: "center", marginTop: "50px", fontFamily: "Arial, sans-serif" }}>
        <h1 style={{ marginBottom: "20px", fontFamily: "Roboto, sans-serif", fontSize: "2.5rem", fontWeight: "bold", color: "#333" }}>What We Protect</h1>
        <p style={{ marginBottom: "40px", fontSize: "1.2rem", lineHeight: "1.6", color: "#666" }}>We cover most electronic devices and appliances. If it has a power source, we can probably cover it.</p>
      </div>
      <div className="container">
        <div className="container">

        </div>

      </div>
    </div>
  );
};

export default Front;
