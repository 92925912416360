

import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

// Styled components
const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  font-family: 'Poppins', sans-serif;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 30px;
  color: #333;
  font-size: 2rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
`;

const Label = styled.label`
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #555;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const InputGroup = styled.div`
  display: flex;
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const Input = styled.input`
  flex: 1;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px 0 0 5px;
  font-size: 1rem;
  color: #333;
  transition: border 0.3s;

  &:focus {
    border-color: #007BFF;
    outline: none;
  }

  @media (max-width: 768px) {
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 0.9rem;
  }
`;

const Button = styled.button`
  padding: 15px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007BFF;
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  transition: background 0.3s;
  font-weight: bold;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    border-radius: 5px;
    font-size: 0.9rem;
    padding: 12px;
  }
`;

const ErrorMessage = styled.div`
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  padding: 12px;
  border-radius: 5px;
  margin-top: 10px;
  width: 100%;
  max-width: 800px;
  text-align: center;
`;

const StatusContainer = styled.div`
  margin-top: 40px;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  background-color: #f9f9f9;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const StatusTitle = styled.h3`
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 1.5rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const StatusItem = styled.div`
  margin-bottom: 15px;
  color: #555;
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const Trackdata = () => {
  const [repairStatus, setRepairStatus] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    const id = form.elements['status'].value;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/aws_api/repairstatus/${id}`
      );
      setRepairStatus(response.data);
      setError(null); // Clear error upon successful response
    } catch (error) {
      console.error('API error:', error);
      setError('Invalid tracking ID. Please try again.'); // Set error message on API error
      setRepairStatus(null);
    }
  };

 
  return (
    <Container>
      <Title>Check Your Repair Status</Title>
      <Form onSubmit={handleSubmit}>
        <Label htmlFor="status">
          Please enter your claim ID <span style={{ color: 'red' }}>*</span>
        </Label>
        <InputGroup>
          <Input
            type="text"
            id="status"
            name="status"
            placeholder="Enter your claim ID"
            required
          />
          <Button type="submit">Track Status</Button>
        </InputGroup>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Form>

      {repairStatus && (
        <StatusContainer>
          <StatusTitle>Repair Status</StatusTitle>
          <div>
            <StatusItem><strong>First Name:</strong> {repairStatus.first_name}</StatusItem>
            <StatusItem><strong>Last Name:</strong> {repairStatus.last_name}</StatusItem>
            <StatusItem><strong>Claim Reason:</strong> {repairStatus.customer_description}</StatusItem>
            <StatusItem><strong>Claim Status:</strong> {repairStatus.internal_status}</StatusItem>
            <StatusItem><strong>Additional information:</strong> {repairStatus.notes_for_customer}</StatusItem>
          </div>
        </StatusContainer>
      )}
    </Container>
  );
};

export default Trackdata;
