import React from "react";
import computerImage from "../images/Laptops.jpg"; // Assuming you have an image file in your project
import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

const Computers = () => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Scroll to top when component mounts

  const handleFileClaim = () => {
    history.push("/claim"); // Navigate to /claim route
    window.scrollTo(0, 0); // Scroll to top after navigation
  };

  return (
    <div className="container-fluid">
      {/* Title container without background color */}
      <div className="p-4 mb-3 text-center">
        <h1 className="font-weight-bold mb-3">Computers</h1>
        <p className="mb-0">Extended Warranty Plan</p>
      </div>

      {/* Image and text container using Bootstrap grid */}
      <div className="row">
        {/* Image column */}
        <div className="col-md-6">
          <div className="text-center mb-5" >
            <img
              src={computerImage}
              alt="computerImage"
              className="img-fluid rounded shadow"
              style={{ maxWidth: "100%", maxHeight: "600px", objectFit: "cover", marginTop: "33px" }}
            />
          </div>
        </div>

        {/* Text column */}
        <div className="col-md-6">
          <div className="p-4">
            <h2 className="font-weight-bold mb-4">
              Alliance Warranty Solutions Extended Warranty for Computers
            </h2>
            <p className="mb-4" style={{ textAlign: "justify" }}>
              Computers have become integral to both our entertainment and business lives, playing a crucial role in our daily activities. Whether you're working on a school project on your laptop or using your tablet on the go for a meeting, these devices enhance our convenience and productivity. While computers are generally robust and can last for years, they are not immune to unexpected malfunctions. No one wants to face the stress of figuring out how to complete an assignment by the end of the week due to a malfunctioning monitor. It's equally disheartening to have to dip into your savings because your brand-new desktop overheated.
              Click link below to start the claim process.
            </p>
            {/* Button positioned at bottom-right corner */}
            <div className="d-flex justify-content-end">
              {/* Use Link to navigate to Form.js */}
              <Link to="/searchwarranty">
                <button
                  type="button"
                  className="btn btn-primary mt-3"
                  onClick={handleFileClaim}
                >
                  File Claim
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Computers;
