import React from "react";
import { Link } from "react-router-dom";
import logoImage from "../images/logo/awslogo1.png";

const Navbar = () => {
  const handleClaimClick = () => {
    window.location.href = "/claim";
  };

  const handleToRefresh = () => {
    window.location.href = "/track";
  };

  

  return (
    <div>
      <nav
        className="navbar navbar-expand-lg navbar-light"
        style={{ backgroundColor: "transparent", boxShadow: "none" }}
      >
        <div className="container d-flex justify-content-between align-items-center">
          <Link
            to="/"
            className="navbar-brand"
            style={{ paddingRight: "0", paddingLeft: "10px" }}
          >
            <img src={logoImage} alt="awslogo" style={{ height: "70px" }} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{ border: "none" }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-center"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="/"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    fontSize: "1rem",
                    color: "#343a40",
                    fontWeight: "bold",
                    marginRight: "20px",
                  }}
                >
                  What We Protect
                </Link>
                <ul className="dropdown-menu mt-3" aria-labelledby="navbarDropdown">
                  <li>
                    <Link to="/appliances" className="dropdown-item">
                      Appliances
                    </Link>
                  </li>
                  <li>
                    <Link to="/camera" className="dropdown-item">
                      Camera & Gaming
                    </Link>
                  </li>
                  <li>
                    <Link to="/computers" className="dropdown-item">
                      Computers
                    </Link>
                  </li>
                  <li>
                    <Link to="/smartphones" className="dropdown-item">
                      Phones
                    </Link>
                  </li>
                  <li>
                    <Link to="/tv" className="dropdown-item">
                      TV & Monitors
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/about"
                  style={{
                    fontSize: "1rem",
                    color: "#343a40",
                    fontWeight: "bold",
                    marginRight: "20px",
                  }}
                >
                  About
                </Link>
              </li>
            </ul>
          </div>
          <div className="d-flex ms-auto">
            <button
              className="btn btn-primary btn-sm"
              onClick={handleClaimClick}
              style={{ padding: "8px 15px", borderRadius: "25px", marginLeft: "10px" }} // Rounded corners
            >
              File your Claim
            </button>

            <Link to="/track" style={{ textDecoration: "none", marginLeft: "10px" }}>
              <button
              onClick={handleToRefresh}
                className="btn btn-primary btn-sm"
                style={{ padding: "8px 15px", borderRadius: "25px" }} // Rounded corners
              >
                Track your claim
              </button>
            </Link>
          </div>
        </div>
      </nav>
<style>
        {`
          @media (max-width: 768px) {
            .btn {
              font-size: 0.9rem;
              padding: 8px 15px; /* Adjusting button padding for small screens */
            }

            .navbar-brand img {
              height: 50px; /* Adjust logo size on small screens */
            }

            .navbar-nav .nav-link {
              font-size: 0.9rem;
            }
          }
        `}
      </style>
    </div>
  );
};

export default Navbar;
