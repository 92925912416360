import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';





const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px;
  font-family: 'Poppins', sans-serif;

  @media (max-width: 768px) {
    padding: 20px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
`;



const Card = styled.div`
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s;
  padding: 20px;
  min-height: 400px;

  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
    min-height: 300px;
  }
`;




const Title = styled.h2`
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
  color: #333;
  font-weight: bold;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const Label = styled.label`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #555;
  font-weight: bold;
`;

const RequiredAsterisk = styled.span`
  color: red;
  margin-left: 0.25rem;
`;

const Input = styled.input`
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  color: #333;
  transition: border 0.3s;

  &:focus {
    border-color: #007BFF;
  }
`;



const Button = styled.button`
  padding: 12px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007BFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const BackButton = styled(Button)`
  background-color: #6c757d;

  &:hover {
    background-color: #5a6268;
  }
`;



const StyledLink = styled(Link)`
  display: block;
  color: #007BFF;
  text-decoration: none;
  margin-top: 10px;  // Reduce the margin to bring it higher on the screen

  &:hover {
    text-decoration: underline;
    color: #0056b3;
  }

  @media (max-width: 576px) {  // Adjust for smaller screens like iPhone 12
    font-size: 0.9rem;
    margin-top: 5px;  // Further reduce margin on smaller screens
  }
`;

const Alert = styled.div`
  background-color: #f8d7da;
  color: #721c24;
  padding: 12px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 1rem;
  font-weight: bold;
`;

const Spinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .spinner-border {
    width: 3rem;
    height: 3rem;
    border-width: 5px;
  }

  p {
    font-size: 1rem;
    margin-top: 10px;
    font-weight: bold;
  }
`;

const StyledLinkContainer = styled.div`
  text-align: center;
  margin-top: 5px;  // Reduce margin to bring it closer to the content above
  font-size: 1.1rem;
  font-weight: bold;

  @media (max-width: 576px) {  // Adjust for smaller screens like iPhone 12
    font-size: 1rem;
    margin-top: 10px;  // Ensure it's separated but closer to other elements
  }
`;


const CustomerCard = styled.div`
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  transition: box-shadow 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
`;

const CustomerInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const CustomerName = styled.h3`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
`;

const CustomerDetail = styled.p`
  font-size: 1rem;
  color: #666;
  margin: 0;
`;

const SelectButton = styled(Button)`
  background-color: #28a745;

  &:hover {
    background-color: #218838;
  }
`;

const StepContainer = styled.div`
  position: relative;
  width: 100%;
  transition: transform 0.5s ease-in-out;
  transform: ${({ $show }) => ($show ? 'translateX(0)' : 'translateX(100%)')};
  display: ${({ $show }) => ($show ? 'block' : 'none')};

  @media (max-width: 768px) {
    padding: 10px;
  }

  @media (max-width: 480px) {
    padding: 5px;
  }
`;


const SearchWarranty = () => {
  const [customerName, setCustomerName] = useState('');
  const [transactionNumber, setTransactionNumber] = useState('');
  const [brandName, setBrandName] = useState('');
  const [modelNumber, setModelNumber] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/aws_api/get_customer_information/`, {


        
        // for the deployement 
        // const response = await fetch(`${process.env.REACT_APP_API_URL}/aws_api/get_customer_information/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customer_name: customerName.trim(),
          transaction_number: transactionNumber.trim(),
          brand_name: brandName.trim(),
          model_number: modelNumber.trim()
        }),
      });

      const data = await response.json();
      setLoading(false);

      if (response.ok) {
        setSearchResults(data);
        if (data.length === 1) {
          setSelectedCustomer(data[0]);
          setStep(6);
        } else {
          setStep(5);
        }
      } else {
        setSearchResults([]);
        setError('Some of the entered details are incorrect. Please enter the correct details. ');
      }
    } catch (error) {
      setSearchResults([]);
      setError('Some of the entered details are incorrect. Please enter the correct details. ');
      setLoading(false);
    }
  };

  const handleNextStep = (event) => {
    event.preventDefault();
    if (step === 1 && !customerName.trim()) {
      setError('Customer name is required.');
      return;
    } else if (step === 2 && !brandName.trim()) {
      setError('Brand name is required.');
      return;
    } else if (step === 3 && !modelNumber.trim()) {
      setError('Model number is required.');
      return;
    } else if (step === 4 && !transactionNumber.trim()) {
      setError('Transaction number is required.');
      return;
    }
    setError('');
    setStep(step + 1);
  };

  const handleBackStep = (event) => {
    event.preventDefault();
    setStep(step - 1);
  };

  const handleSelectCustomer = (customer) => {
    setSelectedCustomer(customer);
    setStep(6);
  };

  const handleBackToResults = () => {
    setStep(5);
    setSelectedCustomer(null);
  };

  const handleFileClaim = () => {
    history.push('/claim', { customer: selectedCustomer });
  };

  const isWarrantyValid = (warrantyExpiryDate) => {
    const today = new Date();
    const expiryDate = new Date(warrantyExpiryDate);
    return expiryDate >= today;
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  return (
    <Container style={{ minHeight: '500px' }}>
      <Card style={{ padding: '15px', minHeight: '480px' }}>
        <Title>Search and File your claim</Title>
        <div style={{ position: 'relative', height: 'auto', minHeight: '200px' }}>
          <StepContainer $show={step === 1}>
            <Form onSubmit={handleNextStep}>
              <Label htmlFor="customerName">
                Customer Name<RequiredAsterisk>*</RequiredAsterisk>
              </Label>
              <Input
                type="text"
                id="customerName"
                placeholder="Enter your first and last name"
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
                required
              />
              {error && step === 1 && <Alert>{error}<StyledLink to="/claim">File a claim manually</StyledLink></Alert>}
              <Button type="submit">Next</Button>
              {!selectedCustomer || isWarrantyValid(selectedCustomer.warranty_expiry_date) ? (
                <StyledLinkContainer>
                  OR <StyledLink to="/claim">File a claim manually</StyledLink>
                </StyledLinkContainer>
              ) : null}
            </Form>
          </StepContainer>
          <StepContainer $show={step === 2}>
            <Form onSubmit={handleNextStep}>
              <Label htmlFor="brandName">
                Brand Name<RequiredAsterisk>*</RequiredAsterisk>
              </Label>
              <Input
                type="text"
                id="brandName"
                placeholder="Enter the brand name"
                value={brandName}
                onChange={(e) => setBrandName(e.target.value)}
                required
              />
              {error && step === 2 && <Alert>{error}<StyledLink to="/claim">File a claim manually</StyledLink></Alert>}
              <Button type="submit">Next</Button>
              <BackButton onClick={handleBackStep}>Back</BackButton>
            </Form>
          </StepContainer>
          <StepContainer $show={step === 3}>
            <Form onSubmit={handleNextStep}>
              <Label htmlFor="modelNumber">
                Model Number<RequiredAsterisk>*</RequiredAsterisk>
              </Label>
              <Input
                type="text"
                id="modelNumber"
                placeholder="Enter the model number"
                value={modelNumber}
                onChange={(e) => setModelNumber(e.target.value)}
                required
              />
              {error && step === 3 && <Alert>{error}<StyledLink to="/claim">File a claim manually</StyledLink></Alert>}
              <Button type="submit">Next</Button>
              <BackButton onClick={handleBackStep}>Back</BackButton>
            </Form>
          </StepContainer>
          <StepContainer $show={step === 4}>
            <Form onSubmit={handleSubmit}>
              <Label htmlFor="transactionNumber">
                Transaction Number<RequiredAsterisk>*</RequiredAsterisk>
              </Label>
              <Input
                type="text"
                id="transactionNumber"
                placeholder="Enter your transaction number"
                value={transactionNumber}
                onChange={(e) => setTransactionNumber(e.target.value)}
                required
              />
              {error && step === 4 && <Alert>{error}<StyledLink to="/claim">File a claim manually</StyledLink></Alert>}
              <Button type="submit">Search</Button>
              <BackButton onClick={handleBackStep}>Back</BackButton>
            </Form>
          </StepContainer>
          {loading && (
            <Spinner>
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <p>Almost there...</p>
            </Spinner>
          )}
          {error && !loading && step === 5 && (
            <Alert>
              {error}<StyledLink to="/claim">File a claim manually</StyledLink>
            </Alert>
          )}
          {step === 5 && searchResults.length > 0 && !loading && (
            <div className="mt-4">
              <h4 style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>Select a Customer</h4>
              {searchResults.map((result, index) => (
                <CustomerCard key={index} onClick={() => handleSelectCustomer(result)}>
                  <CustomerInfo>
                    <CustomerName>{result.customer_name}</CustomerName>
                    <CustomerDetail>Transaction Number: {result.transaction_number}</CustomerDetail>
                    <CustomerDetail>Brand: {result.brand_name}</CustomerDetail>
                    <CustomerDetail>Model: {result.model_number}</CustomerDetail>
                  </CustomerInfo>
                  <SelectButton>Select</SelectButton>
                </CustomerCard>
              ))}
            </div>
          )}
          {step === 6 && selectedCustomer && (
            <div className="mt-4">
              <h4 style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>Selected Customer</h4>
              <p><strong>Customer Name:</strong> {selectedCustomer.customer_name}</p>
              <p><strong>Transaction Number:</strong> {selectedCustomer.transaction_number}</p>
              <p><strong>Brand Name:</strong> {selectedCustomer.brand_name}</p>
              <p><strong>Model Number:</strong> {selectedCustomer.model_number}</p>
              {!isWarrantyValid(selectedCustomer.warranty_expiry_date) && (
                <p><strong>Warranty Expiry Date:</strong> {formatDate(selectedCustomer.warranty_expiry_date)}</p>
              )}
              {isWarrantyValid(selectedCustomer.warranty_expiry_date) ? (
                <div className="mt-4">
                  <h5 style={{ fontSize: '1rem', fontWeight: 'bold' }}>Do you want to file a claim for this product?</h5>
                  <div className="d-grid gap-2">
                    <Button onClick={handleFileClaim} style={{ backgroundColor: '#28a745' }}>Yes, file a claim</Button>
                    <Button onClick={handleBackToResults} style={{ backgroundColor: '#6c757d' }}>No, select another product</Button>
                  </div>
                </div>
              ) : (
                <Alert>The warranty for this product has expired.</Alert>
              )}
            </div>
          )}
        </div>
      </Card>
    </Container>
  );
};

export default SearchWarranty;
