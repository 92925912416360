import React from "react";
import Footer from "./footer";
import { useEffect } from "react";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Scroll to top when component mounts
  return (
    <div>
      <div className="container" style={{ margin: "40px", padding: "20px" }}>
        <h2>Service Contract Agreement</h2>
        <h4>Terms</h4>
        <p>
          Service Contract Agreement Terms and Conditions Definitions Throughout
          this Service Contract, the following definitions apply: <br />
          <br />
          <b>“Covered Product” </b>means the consumer product used primarily for
          personal, family, or household purposes and covered by this Service
          Contract. <b>“Customer”</b>, <b>You</b> or <b>Your</b> means the
          purchaser of the Service Contract including the Lessee, if the Covered
          Product was acquired under a lease-to-own arrangement ("LTO
          Arrangement").
          <b>“Failure”</b> means the mechanical, electrical, or physical
          breakdown of the Covered Product caused by defects in materials or
          workmanship or by normal wear and tear. <b>“Lessee”</b> means, under
          an LTO Arrangement, the payor Customer leasing the Covered Product and
          this Service Contract.
          <b>"Lessor"</b> means, under an LTO Arrangement, the owner of the
          Covered Product and payee of cash benefits under this Service Contract
          until You fulfill Your obligations under the LTO Arrangement necessary
          to acquire full ownership of the Covered Product and this Service
          Contract. <b>“Service Contract”</b> means this contract and the sales
          receipt for this contract, which receipt is incorporated herein by
          reference.
          <b>“Start Date”</b> means the date coverage under this Service
          Contract begins and is the date of purchase of this Service Contract
          as indicated on the sales receipt. <b>“We”</b>, <b>“Us”</b>, and{" "}
          <b>“Our”</b> means the party obligated under the terms and conditions
          of this Service Contract (the “Obligor”), which is Alliance Warranty
          Solutions, in all states where authorized by applicable law, and the
          retailer of the covered product in all other states.
        </p>
      </div>
      <div
        className="container"
        style={{
          marginTop: "10px",
          marginRight: "40px",
          marginBottom: "40px",
          marginLeft: "40px",
          padding: "20px",
        }}
      >
        <h2>Service and Coverage</h2>
        <p>
          This Service Contract covers the cost of repairing or replacing a
          mechanical or electrical failure of the Covered Product caused by
          defects in workmanship or materials or by normal wear and tear. The
          Covered Product may either be repaired or may be replaced at Our
          discretion with an equivalent model of like kind and quality, or a
          voucher may be issued at Our discretion for a comparable product. The
          replacement may be new or rebuilt to meet the manufacturer’s
          specifications of the original product. Our liability is limited, at
          Our option, to the lesser of: 1) the actual cost of repair; 2)
          replacement of the Covered Product or any defective part; or 3) the
          original purchase price of the Covered Product. Replacement parts may
          be either new or remanufactured and will be obtained at the least
          expensive exchange-basis possible. Parts that are replaced become Our
          property and are to be returned to Us upon request, at Our cost,
          subject to applicable state law. Coverage commences on the date the
          Customer takes possession of the Covered Product and includes the
          manufacturer’s factory warranty period. Parts and services covered by
          the manufacturer during the manufacturer’s warranty period will be the
          manufacturer’s responsibility. If the product requires repair during
          the manufacturer’s warranty period, any charges covered under this
          Service Contract that are not covered by the manufacturer will be
          reimbursed. <br />
          <br />
          This Service Contract can only be bought at the time the product is
          purchased <br />
          <br />
          All repairs must be performed by authorized service centers selected
          at Our discretion. Unauthorized repairs may result in denial of the
          claim and the cost of repair will be the responsibility of the
          Customer. In-home service is provided on all products where the
          manufacturer authorizes such service during the full term of the
          manufacturer’s warranty period. All other service will be performed on
          a carry-in basis, or shipment of the Covered Product to an authorized
          service center may be required. <br /> <br />
          If repair is required, call 1-800-419-2323 to report Failure. Unless
          authorized by Us, the product should not be returned to the dealer. If
          the Failure is covered under this Service Contract, an authorized
          service center will be identified.Original proof of purchase and this
          document may be required before service is authorized. While we try to
          complete service as quickly as possible, We are not responsible for
          delays caused by factors beyond Our control, including, but not
          limited to manufacturer’s delays or acts of God. <br /> <br />
          If You acquired the Covered Product and this Service Contract through
          a lease under an LTO Arrangement, You are entitled to all non-cash
          benefits under this Service Contract until You acquire full ownership
          of this Service Contract and the Covered Product. Until You acquire
          full ownership of this Service Contract and the Covered Product, any
          rights to a cash settlement or cancellation refund under this Service
          Contract will belong exclusively to the Lessor. Upon acquiring full
          ownership of this Service Contract and the Covered Product, You are
          entitled to all benefits under this Service Contract.
        </p>
      </div>
      <div
        className="container"
        style={{
          marginTop: "10px",
          marginRight: "40px",
          marginBottom: "40px",
          marginLeft: "40px",
          padding: "20px",
        }}
      >
        <h2>Special Benefits</h2>
        <p>
          <b>Electrical Surge </b> – This Service Contract covers Failure
          resulting from power surge. <br /> <b>Food Spoilage</b> – The cost of
          replacing food that spoiled as a result of a covered failure of a
          refrigerator or freezer will be covered to a maximum of $200.00 per
          service repair during the term of this Service Contract. <br />{" "}
          <b>Remote Controls</b> – This Service Contract provides for the repair
          or replacement of operating components that fail as a result of
          mechanical or electrical failure. We may choose, at our option, to
          replace the item with a like kind and quality universal remote
          control. <br />
          <b>Replacement Guarantee </b> – If the Covered Product has three
          service repairs completed, which repairs are covered by this Service
          Contract, and a fourth such repair is required, as determined by Us,
          within any twelve (12) month period, the Covered Product will be
          replaced with a comparable product. The cost of the replacement will
          not exceed the original product purchase price and may be less due to
          technological advances. We reserve the right to issue a voucher for
          the original purchase price. Any remaining duration of this Service
          Contract will be terminated. Preventative maintenance checks,
          cleaning, product diagnosis, customer education, accessory
          repairs/replacements, computer software related problems, and any
          unauthorized repairs done outside of the U.S.A. are not considered
          repairs for the purposes of this Replacement Guarantee. <br />
          <b>Over-the Counter Exchange Coverage </b>– If so indicated, this
          Service Contract is available for a term of up to twenty-four (24)
          months commencing on the original product purchase date and provides
          for the replacement of the covered product found to be defective with
          an equivalent model of like kind and quality with a price not greater
          than the retail purchase price of the original product. A claim must
          be filed at www.alliancewarrantysolutions.com, and an authorization to
          replace the product will be issued after a valid claim is determined.
          This optional coverage is available for small appliances and
          electronics up to a retail purchase price of $199.99. An Over the
          Counter Exchange of any covered product shall fulfill all obligations
          under this Service Contract. If you wish to obtain replacement
          coverage on the exchanged product, a new Service Contract must be
          purchased.
        </p>
      </div>
      <div
        className="container"
        style={{
          marginTop: "10px",
          marginRight: "40px",
          marginBottom: "40px",
          marginLeft: "40px",
          padding: "20px",
        }}
      >
        <h2>Conditions and Exclusions</h2>
        <p>
          The Covered Product must be maintained in accordance with the
          requirements and/or recommendations set forth by the manufacturer.
          Evidence of proper maintenance and/or service may be required to
          validate a claim. Failures due to lack of proper maintenance or
          cleaning will not be covered, subject to applicable state law.
          Failures caused by any of the following are not covered:
        </p>
        <ul>
          <li>Neglect, abuse or misuse of the product</li>
          <li>
            Failure to replenish or replace coolants, lubricants or any other
            fluid required by the manufacturer
          </li>
          <li>
            Damage caused by non-authorized repair personnel, subject to
            applicable state law
          </li>
          <li>
            Accidental or intentional physical damage, spilled liquids or insect
            infestation
          </li>
          <li>Corrosion, blockages, denting or scratching</li>
          <var>
            <li>Acts of God</li>
          </var>
          <li>Manufacturer recall</li>
          <li>
            War, invasion or act of a foreign enemy, hostilities, civil war,
            rebellion, riot, strike, labor disturbance, lockout, or civil
            commotion
          </li>
          <li>
            Any external cause such as fire, water ingress, sand, theft or
            weather.
          </li>
        </ul>
        <p>The following are not covered by this Service Contract:</p>
        <ul>
          <li>Modifications to the Covered Product not authorized by Us.</li>
          <li>
            Failure resulting from commercial use, public rental, or communal
            use in multi-family housing, unless the amount of use does not
            exceed that expected in a single-family home and is nonrevenue
            producing. Notwithstanding the foregoing list, this section does not
            apply to LTO Transactions;
          </li>
          <li>Any conditions existing prior to the Start Date.</li>
          <li>Preventative maintenance.</li>
          <li>
            Cost of installation, removal, or reinstallation of the Covered
            Product
          </li>
          <li>Any loss other than a Failure of the Covered Product.</li>
          <li>Any product with a removed or altered serial number.</li>
          <li>
            Failure covered by any other warranty or service contact or any
            insurance.
          </li>
          <li>
            Any replacement of consumable or disposable items, such as knobs,
            batteries, filters, bulbs, spark plugs, stylus, vacuum cleaner bags
            and belts, printer cartridges.
          </li>
          <li>
            Replacement of refrigerant coolant unless loss is caused by a
            covered failure.
          </li>
          <li>
            Repair of cosmetic damage where the function of the Covered Product
            is unaffected.
          </li>
          <li>Loss of use of the product or consequential loss of any kind.</li>
          <li>
            Loss or damage to stored data; repairs related to installed
            software; computer virus protection, detection and/or repair; and
            computer hardware added after the original purchase.
          </li>
          <li>International coverage is not available.</li>
        </ul>
      </div>
      <div
        className="container"
        style={{
          marginTop: "10px",
          marginRight: "40px",
          marginBottom: "40px",
          marginLeft: "40px",
          padding: "20px",
        }}
      >
        <h2>Cancellation</h2>
        <p>
          We may cancel this Service Contract for fraud or material
          misrepresentation, including but not limited to commercial or rental
          use (other than an LTO Arrangement). Unauthorized repair or
          replacement shall result in the cancellation of this Service Contract,
          subject to applicable state law. In the event of cancellation by Us,
          written notification of cancellation shall be mailed not less than
          forty-five (45) days before cancellation is effective, which notice
          shall state the effective date of and reason for cancellation. The
          Customer may cancel this Service Contract at any time by mailing or
          delivering to the Dealer of the Service Contract Administrator notice
          of cancellation. If cancelled within thirty (30) days of the Service
          Contract application date, a full refund of the price paid for the
          Service Contract will be made provided no service has been performed.
          After thirty (30) days, a pro-rata refund, less reasonable handling
          costs, and the cost of claims paid will be made. A ten percent (10%)
          penalty per month applies to any refund not paid or credited within
          thirty (30) days of Our receipt of a returned Service Contract.
        </p>
        <h2>Transfer</h2>
        <p>
          Under an LTO Arrangement, this Service Contract is transferable to the
          original Lessee who has acquired full ownership of the Covered
          Product; otherwise, this Service Contract may not be transferred.
        </p>
      </div>
      <div
        className="container"
        style={{
          marginTop: "10px",
          marginRight: "40px",
          marginBottom: "40px",
          marginLeft: "40px",
          padding: "20px",
        }}
      >
        <h2>Arbitration</h2>
        <p>
          To the extent permitted by applicable law, any controversy or dispute
          arising out of or relating to this Service Contract, or breach
          thereof, will be settled by binding arbitration in accordance with the
          Commercial Arbitration Rules adopted by the American Arbitration
          Association. A judgment upon the award rendered by the arbitrator(s)
          may be entered in any court having competent jurisdiction. The parties
          specifically agree to the binding nature of the arbitration.
        </p>
        <br />
        <br />
        <p>
          <b>Tennessee </b>: This Service Contract is automatically extended while the
          product is being repaired.
        </p>
        <br />
        <br />
        <p>
          <b>Alabama </b>:This right to void the Service Contract is not transferable
          and applies only to the original Service Contract purchaser. If You
          cancel this Service Contract otherwise, You will be provided a pro
          rata refund less reasonable handling costs, any claims that may have
          been paid, and an administrative fee of twenty-five ($25.00) dollars.
        </p>
      </div>
      <Footer/>
    </div>
  );
};

export default Terms;
