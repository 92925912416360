import React, { useState, useEffect } from "react";
import StatesDropdown from "./State";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import heic2any from 'heic2any';
import imageCompression from "browser-image-compression";


const Form = () => {
  const location = useLocation(); // Use useLocation to get the state
  const history = useHistory();

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    email: "",
    phone_number: "",
    transaction_number: "",
    brand: "",
    model_number: "",
    serial_number: "",
    customer_description: "",
    front_photo: null,
    back_photo: null,
  });

  const [errors, setErrors] = useState({});
 // eslint-disable-next-line no-unused-vars
  const [fileErrors, setFileErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state
  const [isProcessingImage, setIsProcessingImage] = useState(false); // Add image processing state




   useEffect(() => {
    if (location.state && location.state.customer) {
        const { customer_name, transaction_number, address, city, state, zipcode, brand_name, model_number } = location.state.customer;
        
        const nameParts = customer_name.split(' ');
        const first_name = nameParts.slice(0, -1).join(' '); // All except the last word
        const last_name = nameParts.slice(-1).join(' '); // The last word

        setFormData((prevData) => ({
            ...prevData,
            first_name,
            last_name,
            address: address,  // Already separated in the response
            city: city,
            state: state.toUpperCase(), // Convert state to uppercase
            zipcode: zipcode,
            transaction_number,
            brand: brand_name,
            model_number,
        }));
    }
}, [location.state]);



  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone_number") {
      let formattedPhoneNumber = value;
      formattedPhoneNumber = formattedPhoneNumber.replace(/\D/g, "");
      const match = formattedPhoneNumber.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
      if (match) {
        formattedPhoneNumber = `(${match[1]})${match[2] ? `-${match[2]}` : ""}${match[3] ? `-${match[3]}` : ""}`;
      }
      setFormData({ ...formData, [name]: formattedPhoneNumber });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };







const handleFileChange = async (e) => {
  const { name, files } = e.target;
  const file = files[0];

  const isHeic = file.name.toLowerCase().endsWith(".heic") || file.name.toLowerCase().endsWith(".heif");

  const TARGET_SIZE = 100 * 1024; // 100KB in bytes

  setIsProcessingImage(true); // Start processing (show spinner)

  try {
    let processedFile = file;

    if (isHeic) {
      try {
        const convertedBlob = await heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 0.8,
          timeout: 30000
        });
        processedFile = new File([convertedBlob], `${file.name.split('.')[0]}.jpeg`, { type: 'image/jpeg' });
      } catch (conversionError) {
        console.error("Error converting HEIC/HEIF to JPEG:", conversionError);
        console.warn("Using original HEIC file due to conversion failure");
        setFileErrors(prev => ({ ...prev, [name]: "Failed to convert HEIC/HEIF image." }));
        setIsProcessingImage(false); // Stop processing if an error occurs
        return;
      }
    }

    // Attempt aggressive compression
    let quality = 0.7;
    let maxWidth = 1600;
    let attempts = 0;
    const MAX_ATTEMPTS = 5;

    while (processedFile.size > TARGET_SIZE && attempts < MAX_ATTEMPTS) {
      try {
        const options = {
          maxSizeMB: TARGET_SIZE / (1024 * 1024),
          maxWidthOrHeight: maxWidth,
          useWebWorker: true,
          fileType: "image/jpeg",
          quality: quality
        };
        processedFile = await imageCompression(processedFile, options);
        
        // Adjust parameters for next attempt if needed
        quality = Math.max(quality - 0.1, 0.1);
        maxWidth = Math.max(maxWidth - 200, 800);
        attempts++;
      } catch (compressionError) {
        console.warn("Compression failed:", compressionError);
        break;
      }
    }

    if (processedFile.size > TARGET_SIZE) {
      console.warn(`Unable to compress image below ${TARGET_SIZE} bytes. Final size: ${processedFile.size} bytes`);
      setFileErrors(prev => ({ ...prev, [name]: `Unable to compress image below ${TARGET_SIZE / 1024} KB.` }));
    } else {
      setFileErrors(prev => ({ ...prev, [name]: null }));
    }

    // Update form data with the processed image file
    setFormData(prev => ({ ...prev, [name]: processedFile }));
  } catch (error) {
    console.error("Error while processing image:", error);
    setFileErrors(prev => ({ ...prev, [name]: "Failed to process the image." }));
  } finally {
    setIsProcessingImage(false); // Stop processing when done (hide spinner)
  }
};







  


  const validateForm = () => {
    const newErrors = {};

    if (!formData.first_name) newErrors.first_name = "First name is required.";
    if (!formData.last_name) newErrors.last_name = "Last name is required.";
    if (!formData.address) newErrors.address = "Address is required.";
    if (!formData.city) newErrors.city = "City is required.";
    if (!formData.state) newErrors.state = "State is required.";
    if (!formData.zipcode) newErrors.zipcode = "Zip code is required.";
    if (!formData.email) newErrors.email = "Email is required.";
    if (!formData.phone_number) newErrors.phone_number = "Phone number is required.";
    if (!formData.model_number) newErrors.model_number = "Model number is required.";
    if (!formData.serial_number) newErrors.serial_number = "Serial number is required.";
    if (!formData.front_photo) newErrors.front_photo = "Front photo is required.";
    if (!formData.back_photo) newErrors.back_photo = "Back photo is required.";
    if (!formData.customer_description) newErrors.customer_description = "Return reason is required.";
    if (!termsChecked) newErrors.termsChecked = "You must agree to the terms and conditions.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      setLoading(true); 
      try {
        const formDataToSend = new FormData();
        Object.entries(formData).forEach(([key, value]) => {
          formDataToSend.append(key, value);
        });

        // Append additional data from location.state if customer is coming from SearchWarranty
        if (location.state && location.state.customer) {
          const { customer_name, transaction_number, address, brand_name, model_number } = location.state.customer;
          formDataToSend.append("customer_name", customer_name);
          formDataToSend.append("transaction_number", transaction_number);
          formDataToSend.append("address", address);
          formDataToSend.append("brand_name", brand_name);
          formDataToSend.append("model_number", model_number);
        }

        const apiUrl = process.env.REACT_APP_API_URL;


        const response = await axios.post(`${apiUrl}/aws_api/requestclaim/`, formDataToSend);




// EXTRA GAIN FOR THE EMAIL THING


axios.defaults.validateStatus = function (status) {
  return status >= 200 && status < 300; // default
}


        if (response.status !== 201) {
          console.warn('Unexpected status code:', response.status);
          console.log('Response data:', response.data);
        }
        // for the deployement 
        // const response = await axios.post(`${apiUrl}/aws_api/requestclaim/`, formDataToSend);


        const generatedId = response.data.id;

        setSubmitted(true);

        setTimeout(() => {
          event.target.reset();
          setFormData({
            first_name: "",
            last_name: "",
            address: "",
            city: "",
            state: "",
            zipcode: "",
            email: "",
            phone_number: "",
            transaction_number: "",
            brand: "",
            model_number: "",
            serial_number: "",
            customer_description: "",
            front_photo: null,
            back_photo: null,
          });
          setSubmitted(false);
          setTermsChecked(false);
          setLoading(false); // Set loading to false after form submission is complete

          history.push(`/notification/${generatedId}`);
        }, 1000);
      } catch (error) {
        console.error("Form submission error:", error);


        // extra code for the email 
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('Error data:', error.response.data);
          console.error('Error status:', error.response.status);
          console.error('Error headers:', error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.error('Error request:', error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error message:', error.message);
        }




        setLoading(false); // Set loading to false if there's an error
      }
    }
  };

  const handleTermsCheckboxChange = (e) => {
    setTermsChecked(e.target.checked);
  };

  const handleTermsClick = () => {
    history.push("/terms");
  };

  return (
    <div className="container">
	{(isProcessingImage || loading) && (
         <div className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center" style={{ backgroundColor: 'rgba(0,0,0,0.5)', zIndex: 1000 }}>
           <div className="spinner-border text-light" role="status">
             <span className="visually-hidden">Loading...</span>
           </div>
         </div>
       )}


      <div className="row justify-content-center">
        <div className="col-lg-8">
          {!submitted && Object.keys(errors).length > 0 && (
            <div className="alert alert-danger" role="alert">
              Please fill out all required fields correctly.
            </div>
          )}
          {loading ? (
            <div className="text-center mt-4">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <p style={{ fontSize: '16px', marginTop: '10px' }}>Filing a claim...</p>
            </div>
          ) : (
          <form
            className="needs-validation"
            noValidate
            onSubmit={handleSubmit}
            encType="multipart/form-data"
            style={{
              marginTop: "50px",
              background: "#f8f9fa",
              padding: "30px",
              borderRadius: "10px",
            }}
          >
            <div className="bg-white p-4 rounded mb-4">
              <h3 className="text-center mb-0">File a Claim</h3>
            </div>

            <div className="row g-3">
              {!location.state?.customer && (
                <>
                  <div className="col-md-6">
                    <label htmlFor="first_name" className="form-label">
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errors.first_name ? "is-invalid" : ""}`}
                      id="first_name"
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleInputChange}
                      required
                    />
                    <div className="invalid-feedback">
                      {errors.first_name || "Please provide a valid first name."}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="last_name" className="form-label">
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errors.last_name ? "is-invalid" : ""}`}
                      id="last_name"
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleInputChange}
                      required
                    />
                    <div className="invalid-feedback">
                      {errors.last_name || "Please provide a valid last name."}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="address" className="form-label">
                      Address <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errors.address ? "is-invalid" : ""}`}
                      id="address"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                      required
                    />
                    <div className="invalid-feedback">
                      {errors.address || "Please provide a valid address."}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="city" className="form-label">
                      City <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errors.city ? "is-invalid" : ""}`}
                      id="city"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                      required
                    />
                    <div className="invalid-feedback">
                      {errors.city || "Please provide a valid city."}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="state" className="form-label">
                      State <span className="text-danger">*</span>
                    </label>
                    <StatesDropdown
                      selectedState={formData.state}
                      setSelectedState={(value) =>
                        setFormData({ ...formData, state: value })
                      }
                    />
                    <div className={`invalid-feedback ${errors.state ? "d-block" : ""}`}>
                      {errors.state || "Please select a state."}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="zipcode" className="form-label">
                      Zip Code <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errors.zipcode ? "is-invalid" : ""}`}
                      id="zipcode"
                      name="zipcode"
                      value={formData.zipcode}
                      onChange={handleInputChange}
                      pattern="[0-9]{5}"
                      maxLength="5"
                      inputMode="numeric"
                      required
                    />
                    <div className="invalid-feedback">
                      {errors.zipcode || "Please provide a valid zip code."}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="transaction_number" className="form-label">
                      Transaction
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="transaction_number"
                      name="transaction_number"
                      value={formData.transaction_number}
                      onChange={handleInputChange}
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="brand" className="form-label">
                      Brand
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="brand"
                      name="brand"
                      value={formData.brand}
                      onChange={handleInputChange}
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="model_number" className="form-label">
                      Model Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errors.model_number ? "is-invalid" : ""}`}
                      id="model_number"
                      name="model_number"
                      value={formData.model_number}
                      onChange={handleInputChange}
                      required
                    />
                    <div className="invalid-feedback">
                      {errors.model_number || "Please provide a valid model number."}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="serial_number" className="form-label">
                      Serial Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errors.serial_number ? "is-invalid" : ""}`}
                      id="serial_number"
                      name="serial_number"
                      value={formData.serial_number}
                      onChange={handleInputChange}
                      required
                    />
                    <div className="invalid-feedback">
                      {errors.serial_number || "Please provide a valid serial number."}
                    </div>
                  </div>
                </>
              )}

              {location.state?.customer && (
                <>
                  <div className="col-md-6">
                    <label htmlFor="serial_number" className="form-label">
                      Serial Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errors.serial_number ? "is-invalid" : ""}`}
                      id="serial_number"
                      name="serial_number"
                      value={formData.serial_number}
                      onChange={handleInputChange}
                      required
                    />
                    <div className="invalid-feedback">
                      {errors.serial_number || "Please provide a valid serial number."}
                    </div>
                  </div>
                </>
              )}

              <div className="col-md-6">
                <label htmlFor="email" className="form-label">
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  className={`form-control ${errors.email ? "is-invalid" : ""}`}
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
                <div className="invalid-feedback">
                  {errors.email || "Please provide a valid email address."}
                </div>
              </div>

              <div className="col-md-6">
                <label htmlFor="phone_number" className="form-label">
                  Phone Number <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className={`form-control ${errors.phone_number ? "is-invalid" : ""}`}
                  id="phone_number"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleInputChange}
                  maxLength="14"
                  required
                  pattern="\(\d{3}\)-\d{3}-\d{4}"
                  title="Phone number must be in the format (123)-456-7890"
                />
                <div className="invalid-feedback">
                  {errors.phone_number || "Please provide a valid phone number in the format (123)-456-7890."}
                </div>
              </div>

              <div className="col-md-6">
                <label htmlFor="front_photo" className="form-label">
                  Front Photo <span className="text-danger">*</span>
                </label>
                <input
                    type="file"
                    accept="image/heic, image/jpeg, image/png"
                    className={`form-control ${errors.front_photo ? "is-invalid" : ""}`}
                    id="front_photo"
                    name="front_photo"
                    onChange={handleFileChange}
                    required
                  />
                {errors.front_photo && (
                  <div className="invalid-feedback">
                    {errors.front_photo}
                  </div>
                )}
              </div>

              <div className="col-md-6">
                <label htmlFor="back_photo" className="form-label">
                  Back Photo/Model/Serial Tag <span className="text-danger">*</span>
                </label>
                <input
                    type="file"
                    accept="image/heic, image/jpeg, image/png"
                    className={`form-control ${errors.back_photo ? "is-invalid" : ""}`}
                    id="back_photo"
                    name="back_photo"
                    onChange={handleFileChange}
                    required
                  />
                {errors.back_photo && (
                  <div className="invalid-feedback">
                    {errors.back_photo}
                  </div>
                )}
              </div>

              <div className="col-12">
                <label htmlFor="customer_description" className="form-label">
                  Reason for Claim <span className="text-danger">*</span>
                </label>
                <textarea
                  className={`form-control ${errors.customer_description ? "is-invalid" : ""}`}
                  id="customer_description"
                  name="customer_description"
                  value={formData.customer_description}
                  onChange={handleInputChange}
                  required
                />
                <div className="invalid-feedback">
                  {errors.customer_description || "Return Reason is required."}
                </div>
              </div>

              <div className="col-12">
                <div className="form-check">
                  <input
                    className={`form-check-input ${errors.termsChecked ? "is-invalid" : ""}`}
                    type="checkbox"
                    id="agreeCheck"
                    checked={termsChecked}
                    onChange={handleTermsCheckboxChange}
                    required
                  />
                  <label className="form-check-label" htmlFor="agreeCheck">
                    <span
                      style={{ cursor: "pointer", color: "blue" }}
                      onClick={handleTermsClick}
                    >
                      Agree to terms and conditions
                    </span>
                    <span className="text-danger">*</span>
                  </label>
                  <div className="invalid-feedback">
                    {errors.termsChecked || "You must agree to the terms and conditions."}
                  </div>
                </div>
              </div>

              <div className="col-12">
                <button className="btn btn-primary float-end" type="submit">
                  Submit Form
                </button>
              </div>
            </div>
          </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Form;


