import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";

const StyledFooter = styled.footer`
   background-color: #343a40;
  color: #ffffff;
  padding: 40px 20px;
  margin-top: 50px;

  h5 {
    color: #ffffff;
    margin-bottom: 20px;
  }

  ul {
    padding: 0;
    list-style-type: none;
  }

  li {
    margin-bottom: 10px;
  }

  a {
    color: #ffffff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  p {
    margin-bottom: 10px;
  }

  .contact-details p {
    margin-bottom: 0;
  }

  .contact-center p {
    margin-top: 10px;
  }

  .social-icons {
    margin-top: 20px;
    text-align: center; 

    a {
      color: #ffffff;
      margin: 0 10px;
      font-size: 24px;
      transition: color 0.3s;

      &:hover {
        color: #007bff;
      }
    }
  }

  .copyright {
    margin-top: 30px;
    text-align: center;
  }

  /* Responsive design */
  @media (max-width: 768px) {
    .row {
      flex-direction: column;
      align-items: center;
    }

    .col-md-6 {
      margin-bottom: 20px;
      text-align: center;
    }

    .contact-center {
      margin-top: 20px;
    }

    .social-icons {
      margin-top: 20px;
      text-align: center;
    }
  }

  @media (max-width: 576px) {
    padding: 20px 10px;

    h5 {
      font-size: 18px;
    }

    .social-icons a {
      font-size: 20px;
    }
  }
`;



function Footer() {
  return (
    <StyledFooter>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div>
              <h5>Terms & Conditions</h5>
              <ul>
                <li>
                  <Link to="/terms">Terms and Conditions</Link>
                </li>
                <li>
                  <Link to="/faq">FAQ's</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <h5>Contact Us</h5>
              <div className="contact-details">
                <p>Phone: (800) 419-2323</p>
                <p>
                  Email:{" "}
                  <a href="mailto:help@alliancewarrantysolutions.com">
                    help@alliancewarrantysolutions.com
                  </a>
                </p>
              </div>
              <div className="contact-center">
                <h5>Contact Center</h5>
                <p>Mon - Fri: 8am - 5pm CST</p>
              </div>
            </div>
          </div>
        </div>
          <div className="row copyright">
          <div className="col-12">
            <p>&copy; 2024 Alliance Warranty Solutions | All rights reserved.</p>
          </div>
        </div>
      </div>
    </StyledFooter>
  );
}

export default Footer;
