import React from "react";
import tvImage from "../images/TVs.jpg"; // Assuming you have an image file in your project
import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

const TV = () => {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Scroll to top when component mounts
  const handleFileClaim = () => {
    history.push("/claim"); // Navigate to /claim route
    window.scrollTo(0, 0); // Scroll to top after navigation
  };
  return (
    <div className="container-fluid">
      {/* Title container without background color */}
      <div className="p-4 mb-3 text-center">
        <h1 className="font-weight-bold mb-3">TV & Monitor</h1>
        <p className="mb-0">Extended Warranty Plan</p>
      </div>

      {/* Image and text container using Bootstrap grid */}
      <div className="row">
        {/* Image column */}
        <div className="col-md-6">
          <div className="text-center mb-5" >
            <img
              src={tvImage}
              alt="tvImage"
              className="img-fluid rounded shadow"
              style={{ maxWidth: "100%", maxHeight: "600px", objectFit: "cover", marginTop: "33px" }}
            />
          </div>
        </div>

        {/* Text column */}
        <div className="col-md-6">
          <div className="p-4">
            <h2 className="font-weight-bold mb-4">
              Alliance Warranty Solutions Extended Warranty for HDTVs and
              Monitors
            </h2>
            <p className="mb-0" style={{ textAlign: "justify" }}>
              {/* Your HDTV is a valuable investment for one of the most common
              pastimes. Whether you're taking a load off after a long day or
              you're enjoying your weekend with the family, your TV plays a huge
              role in entertainment. Computer monitors are also a valuable
              investment in the same sense. Whether you’re using your computer
              for editing documents, playing games, or 3D rendering, I'm sure
              you’d like a high-quality screen to match it. Though HDTVs and
              monitors are known for lasting a long time, we also understand
              that accidents happen. It'd be a terrible situation to be in, if
              it’s time to watch the big game, and your TV stops working. For
              your monitor, how long could you tolerate the flickering or
              stuttering, to avoid paying for a repair or replacement? We all
              know that high-quality monitors aren't cheap. With Alliance
              Warranty Solutions, service is only a phone call away, and your
              repair won’t cost you anything. This warranty covers the service
              call, labor charges, and even replacement parts. The best part is,
              you’d be covered no matter where you live. With nationwide
              service, easy replacement and repairs, and no hidden costs or
              deductibles, what better way to take care of something so
              essential to life? Alliance Warranty Solutions is the perfect
              choice for a minimum investment and maximum protection.{" "} */}

              Your HDTV is a significant investment in one of the most beloved pastimes. Whether you're unwinding after a long day or spending quality time with your family on the weekend, your TV is central to your entertainment experience. While HDTVs and monitors are designed for longevity, accidents can still happen. Imagine the frustration of your TV failing right before the big game or enduring a flickering screen because you want to avoid the high costs of repair or replacement. Service is just a click away, and your repair won’t cost you anything. Our warranty covers the service call, labor charges, and even replacement parts. Best of all, you’re covered no matter where you live. Protecting such an essential part of your life has never been easier. Alliance Warranty Solutions offers the perfect combination of minimal investment and maximum protection.
            </p>
            {/* Button positioned at bottom-right corner */}
            <div className="d-flex justify-content-end">
              {/* Use Link to navigate to Form.js */}
              <Link to="/searchwarranty">
                <button
                  type="button"
                  className="btn btn-primary mt-3"
                  onClick={handleFileClaim}
                >
                  File Claim
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TV;
