import React from "react";
import icon1 from "../images/icon/icon-counter1@2x.png";
import icon2 from "../images/icon/icon-counter2@2x.png";
import icon3 from "../images/icon/icon-counter3@2x.png";

const About = () => {
  return (
    <div className="container mt-5 px-3">
      <h2 className="mb-4 font-weight-bold">About Us</h2>
      <p className="mb-4">
        We understand that your hard-earned money is important to you, so it's
        our job to ensure you get the most out of your purchase.
      </p>
      <ul className="ul-list mb-4">
        <li>&#10004; Lowest Cost</li>
        <li>&#10004; Longer terms</li>
        <li>&#10004; Greater Investment</li>
      </ul>

      <div className="row">
        <div className="col-md-4 mb-4">
          <div className="d-flex align-items-center p-3 bg-light">
            <img
              src={icon1}
              alt="icon1"
              style={{ width: "50px", height: "50px", marginRight: "20px" }}
            />
            <div>
              <h3 className="font-weight-bold">1,000,000+</h3>
              <p>MILLIONS OF ITEMS PROTECTED</p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="d-flex align-items-center p-3 bg-light">
            <img
              src={icon2}
              alt="icon2"
              style={{ width: "50px", height: "50px", marginRight: "20px" }}
            />
            <div>
              <h3 className="font-weight-bold">10,000+</h3>
              <p>THOUSANDS OF CUSTOMERS SAVED</p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="d-flex align-items-center p-3 bg-light">
            <img
              src={icon3}
              alt="icon3"
              style={{ width: "50px", height: "50px", marginRight: "20px" }}
            />
            <div>
              <h3 className="font-weight-bold">(800) 419-2323</h3>
              <p>EXCELLENT CUSTOMER SERVICE</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
