import "./App.css";
import React from "react";
import Navbar from "./component/Navbar";
import About from "./component/About";
import Front from "./component/Front";
import Fronta from "./component/Fronta";
import Form from "./component/Form";
import Footer from "./component/footer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Smartphones from "./component/Smartphones";
import Computers from "./component/Computers";
import TV from "./component/TV";
import Camera from "./component/Camera";
import Appliances from "./component/Appliances";
import Terms from "./component/Terms";
import Trackdata from "./component/Trackdata";
import warranty from "./component/Searchwarranty";
import Notification from "./component/Notification";
import FAQ from "./component/FAQ";


function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Switch>
          <Route exact path="/">
            <Front />
            <Fronta />
          </Route>
          {/* Routes to the Navbar li's and the postcards */}
          <Route exact path="/smartphones" component={Smartphones} />
          <Route exact path="/computers" component={Computers} />
          <Route exact path="/tv" component={TV} />
          <Route exact path="/camera" component={Camera} />
          <Route exact path="/appliances" component={Appliances} />
          
        </Switch>
        <Switch>
          <Route>
          <Route exact path="/about" component={About} />
          <Route exact path="/claim" component={Form} />
          <Route exact path="/track" component={Trackdata} />
          <Route exact path="/searchwarranty" component={warranty} />
          <Route path="/notification/:id" component={Notification} />
          </Route>
        </Switch>

        <Switch>
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/faq" component={FAQ} />
        <Footer />
        </Switch>

      </div>
    </Router>
  );
}

export default App;
