import React from "react";
import { useParams } from "react-router-dom";

const Notification = () => {
  const { id } = useParams(); // Extract id from URL parameters

  return (
    <div className="container mt-4">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="card shadow">
            <div className="card-body">
              <h1 className="text-center mb-4">Your Request has been Submitted</h1>
              <p className="text-center mb-4">
                Your claim ID: <strong>{id}</strong>
              </p>
              <p className="text-center mb-4">
                You will receive an email shortly confirming your claim.
              </p>
              <p className="text-center mb-4" style={{ color: "red" }}>
                If you have not received an email, please check your spam folder.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
